<div *ngIf="data.contentType=='image'">
    <!-- Image -->
    <img src="{{data.url}}" class="card-img-top img-responsive" alt="{{data.contentType}}">
</div>
<div *ngIf="data.contentType=='document'">
    <!-- Document -->
    <a href="{{data.url}}" target="_blank"> <img src="assets/img/doc.png" height="50px" class="title-img"
            alt="Document" /> Click to open </a>
</div>
<div *ngIf="data.contentType=='video'">
    <!-- Video -->
    <video controls width="100%">
        <source src="{{data.url}}" type="video/mp4">
        Your browser does not support the video tag.
    </video>
</div>