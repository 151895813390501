import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'linkout',
  templateUrl: './linkout.component.html',
  styleUrls: ['./linkout.component.css']
})
export class LinkoutComponent implements OnInit {
  @Input() data: any;

  constructor() { }

  ngOnInit() {
  }

}
