import { Component, ElementRef, QueryList, ViewChildren, HostListener } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Chat with TOBi';
  chatWindowOpen = true;
  messageInput = '';
  typing = false;
  showChatWindow = true;
  @ViewChildren("chats") chatsDiv: QueryList<ElementRef>;
  container: HTMLElement;

  messages = [];
  tobiReplies = [];
  public constructor(private socket: Socket) {
    this.socket.on('gcpresponse', (data) => {
      console.log('GCP Resp', data);
      this.tobiReplies.push({ isError: false, data });
      this.handleGCPResponse(data);
      this.typing = false;
    });
    this.socket.on('gcperror', (data) => {
      console.log('GCP Error Resp', data);
      this.tobiReplies.push({ isError: true, data });
      this.typing = false;
    });
  }

  @HostListener('window:beforeunload')
  clearSession() {
    this.socket.emit('exit', 'closeChat');
  }

  ngAfterViewInit() {
    this.chatsDiv.changes.subscribe(() => {
      if (this.chatsDiv && this.chatsDiv.last) {
        this.chatsDiv.last.nativeElement.focus();
        this.container = document.getElementById("chatBody");
        this.container.scrollTop = this.container.scrollHeight;
      }
    });
  }

  public sendMessage(msg) {
    msg = msg || this.messageInput;
    if (window.location.href.includes('chat2')) {
      this.socket.emit('message2', msg);
    } else {
      this.socket.emit('message', msg);
    }

    this.messages.push({
      reply: false,
      type: 'text',
      data: msg,
      dateTime: new Date()
    })
    this.messageInput = '';
    this.typing = true;
  }

  private sleep(delay: number) {
    var start = new Date().getTime();
    while (new Date().getTime() < start + delay);
  }

  private getCardCalloutButtons(buttons) {
    let calloutButtons = [];
    buttons.forEach(btn => {
      if (btn.action && btn.action.type === 'openUrl') {
        calloutButtons.push({
          name: btn.title,
          url: btn.action.value
        })
      }
    });
    return calloutButtons;
  }

  private getCardSuggestions(buttons) {
    let suggestions = [];
    buttons.forEach(btn => {
      if (btn.action && btn.action.type === 'imBack') {
        suggestions.push({ textPlain: btn.action.value })
      }
    });
    return suggestions;
  }

  private handleGCPResponse(data) {
    let calloutButtons = [];
    if (data && data.messages && data.messages.message) {
      data.messages.message.forEach(msg => {

        if (msg.content && msg.content.event) {
          if (msg.content.event.pause) {
            this.sleep(msg.content.event.pause);
          } else {
            this.typing = msg.content.event.typing.enable;
          }
        }

        if (msg.content && msg.content.textMessage && msg.content.textMessage.textPlain) {
          this.messages.push({
            reply: true,
            type: 'text',
            data: { text: msg.content.textMessage.textPlain },
            dateTime: new Date()
          })
        }
        if (msg.content && msg.content.quickReplies) {
          //Check if last message is plainText
          if (this.messages[this.messages.length - 1].type === 'text') {
            this.messages[this.messages.length - 1].data.suggestions = msg.content.quickReplies
          } else {
            this.messages.push({
              reply: true,
              type: 'suggestion',
              data: msg.content.quickReplies,
              dateTime: new Date()
            });
          }
        }
        if (msg.content && msg.content.calloutButton) {
          calloutButtons.push(msg.content.calloutButton)
        }
        if (msg.content && msg.content.cards) {
          if (msg.content.cards.length > 1) {
            let cards = [];
            msg.content.cards.forEach(card => {
              cards.push({
                title: card.title,
                subtitle: card.subtitle,
                url: card.image ? card.image.url : card.media && card.media.files && card.media.files[0] ? card.media.files[0].url : false,
                contentType: card.image ? card.image.contentType : card.media && card.media.files && card.media.files[0] ? card.media.files[0].contentType : false,
                desc: card.description,
                btn: card.buttons || [],
                calloutButtons: this.getCardCalloutButtons(card.buttons || []),
                suggestionButtons: this.getCardSuggestions(card.buttons || [])
              });
            });
            console.log("Carousels", cards);
            this.messages.push({
              reply: true,
              type: 'carousel',
              data: cards,
              dateTime: new Date()
            });
          } else {
            let card = msg.content.cards[0];
            this.messages.push({
              reply: true,
              type: 'card',
              data: {
                title: card.title,
                subtitle: card.subtitle,
                url: card.image ? card.image.url : card.media && card.media.files && card.media.files[0] ? card.media.files[0].url : false,
                contentType: card.image ? card.image.contentType : card.media && card.media.files && card.media.files[0] ? card.media.files[0].contentType : false,
                desc: card.description,
                btn: card.buttons || [],
                calloutButtons: this.getCardCalloutButtons(card.buttons || []),
                suggestionButtons: this.getCardSuggestions(card.buttons || [])
              },
              dateTime: new Date()
            });
          }
        }
        //Check for adaptive cards
        if (msg.content && msg.content.attachments) {
          msg.content.attachments.forEach(msg => {
            if (msg.contentType === "application/vnd.microsoft.card.adaptive") {
              this.messages.push({
                reply: true,
                type: 'adaptivecard',
                data: msg.content,
                dateTime: new Date()
              })
            }

            if (msg.contentType) {
              this.messages.push({
                reply: true,
                type: 'attachment',
                data: {
                  contentType: msg.contentType,
                  url: msg.contentUrl
                },
                dateTime: new Date()
              })
            }

          })
        }
      });
      if (calloutButtons.length > 0) {
        //Check if last message is plainText
        if (this.messages[this.messages.length - 1].type === 'text') {
          this.messages[this.messages.length - 1].data.linkouts = calloutButtons
        } else {
          this.messages.push({
            reply: true,
            type: 'linkout',
            data: calloutButtons,
            dateTime: new Date()
          });
        }
      }
    } else {
      console.log('Messages missing check GCP Resp logged above ');
    }
  }
}
