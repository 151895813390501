<div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner special-padding">
            <div class="carousel-item" *ngFor="let card of data; let i =index;" [ngClass]="{'active': i === 0}">
                    <card (chlidAction)=sendMessage($event) class="d-block w-100" [data]=card></card>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon bg-red" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="next">
            <span class="carousel-control-next-icon bg-red" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>