import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import * as AdaptiveCards from 'adaptivecards';

@Component({
  selector: 'adaptivecard',
  templateUrl: './adaptive-card.component.html',
  styleUrls: ['./adaptive-card.component.css']
})
export class AdaptiveCardComponent implements OnInit {

  @Input() data: any;
  @Output() chlidAction = new EventEmitter();
  constructor() { }
  // card container reference
  @ViewChild("cardContainer")
  cardContainer: ElementRef;
  ngAfterViewInit() {
    // 1. create an instance of adaptive cards
    let adaptiveCard = new AdaptiveCards.AdaptiveCard();
    // 2. parse the json payload
    adaptiveCard.parse(this.data);
    // 3. render the card 
    let renderedCard = adaptiveCard.render();
    // 4. attach to the DOM
    this.cardContainer.nativeElement.append(renderedCard);
  }

  ngOnInit() {
  }

  sendMessage(msg: string) {
    this.chlidAction.emit(msg);
  }

}
