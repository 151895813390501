<div class="card">
    <img *ngIf="data.contentType=='image'" src="{{data.url}}" class="card-img-top">
    <div *ngIf="data.contentType=='video'">
        <!-- Video -->
        <video controls width="100%"  class="card-img-top">
            <source src="{{data.url}}" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </div>
    <div class="card-body">
        <!-- TODO: Switch for suggestion chip buttons and linkout buttons -->
        <h5 class="card-title">{{data.title}}</h5>
        <h6 class="card-subtitle">{{data.subtitle}}</h6>
        <p class="card-text">{{data.desc}}</p>
        <!-- <linkout [data]=data.btn></linkout> -->
        <p>
            <linkout *ngIf="data.calloutButtons.length > 0" [data]=data.calloutButtons></linkout>
            <suggestion (chlidAction)=sendMessage($event) *ngIf="data.suggestionButtons.length > 0"
                [data]=data.suggestionButtons></suggestion>
        </p>
    </div>