import { Component, Input ,OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {
  @Input() data: any;
  @Output() chlidAction = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  sendMessage(msg:string){
    this.chlidAction.emit(msg);
  }
}
