import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.css']
})
export class AttachmentComponent implements OnInit {

  @Input() data: any;
  @Output() chlidAction = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  sendMessage(msg:string){
    this.chlidAction.emit(msg);
  }

}
