import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.css']
})
export class SuggestionComponent implements OnInit {
  @Input() data: any;
  @Output() chlidAction = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  sendFeed(msg:string){
    this.chlidAction.emit(msg);
  }

}
