import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  @Input() data: any;
  @Output() chlidAction = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  sendMessage(msg:string){
    this.chlidAction.emit(msg);
  }

}
