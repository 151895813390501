<div class="container">
    <div class="row" *ngIf="showChatWindow">
        <div class="col-3 col-md-3">
        </div>
        <div class="chat-window col-6 col-md-6" id="chat_window_1">
            <div class="row col-12 col-md-12">
                <div class="panel panel-default">
                    <div class="row panel-heading top-bar">
                        <div class="col-8">
                            <h5 class="panel-title">
                                <!-- <span class="fa fa-weixin"></span>  -->
                                <img src="assets/img/tobi-icon.png" class="title-img">
                                {{title}}</h5>
                        </div>
                        <div class="col-4" style="text-align: right;">
                            <div class="row">
                                <div class="col-5"></div>
                                <div class="col-3" style="margin-top: -2px;">
                                    <a class="clickable" (click)="chatWindowOpen = !chatWindowOpen">
                                        <fa *ngIf="chatWindowOpen" class="fa fa-window-minimize" name="controlLogo">
                                        </fa>
                                        <fa class="fa fa-angle-up panel-collapsed" *ngIf="!chatWindowOpen"
                                            name="controlMax"></fa>
                                    </a>
                                </div>
                                <div class="col-3">
                                    <a class="clickable" (click)="showChatWindow = !showChatWindow">
                                        <fa class="fa fa-window-close" name="controlLogo"></fa>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="chatBody" class="panel-body msg_container_base" [ngClass]="{'closed':!chatWindowOpen}">
                        <div id="chatItm" #chats *ngFor="let message of messages; index as i;">
                            <div *ngIf="!message.reply" class="row msg_container base_sent">
                                <div class="col-md-10 col-10">
                                    <div class="messages msg_sent">
                                        <p>{{message.data}}</p>
                                        <time
                                            datetime="2009-11-13T20:00">{{message.dateTime | date : "dd MMM, h:mm:ss a"}}</time>
                                    </div>
                                </div>
                                <div class="col-md-2 col-2 avatar">
                                    <img src="assets/img/geek-user.jpg" class=" img-responsive ">
                                </div>
                            </div>
                            <div *ngIf="message.reply" class="row msg_container base_receive">
                                <div class="col-md-2 col-2 avatar">
                                    <img src="assets/img/chat-button-tobi-m.png" class=" img-responsive ">
                                </div>
                                <div class="col-md-10 col-10">
                                    <div class="messages msg_receive" [ngSwitch]="message.type">
                                        <card (chlidAction)=sendMessage($event) *ngSwitchCase="'card'"
                                            [data]=message.data></card>
                                        <adaptivecard (chlidAction)=sendMessage($event) *ngSwitchCase="'adaptivecard'"
                                            [data]=message.data></adaptivecard>
                                        <carousel (chlidAction)=sendMessage($event) *ngSwitchCase="'carousel'"
                                            [data]=message.data></carousel>
                                        <attachment (chlidAction)=sendMessage($event) *ngSwitchCase="'attachment'"
                                            [data]=message.data></attachment>
                                        <p>
                                            <linkout *ngSwitchCase="'linkout'" [data]=message.data></linkout>
                                        </p>
                                        <p>
                                            <suggestion (chlidAction)=sendMessage($event) *ngSwitchCase="'suggestion'"
                                                [data]=message.data></suggestion>
                                        </p>
                                        <p *ngSwitchDefault>
                                            {{message.data.text}} <br />
                                            <suggestion (chlidAction)=sendMessage($event)
                                                *ngIf="message.data.suggestions" [data]=message.data.suggestions>
                                            </suggestion>
                                            <linkout *ngIf="message.data.linkouts" [data]=message.data.linkouts>
                                            </linkout>
                                        </p>
                                        <time
                                            datetime="2009-11-13T20:00">{{message.dateTime | date : "dd MMM, h:mm:ss a"}}</time>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="typing-indicator" *ngIf="typing">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div class="panel-footer" *ngIf="chatWindowOpen">
                        <div class="input-group">
                            <input id="btn-input" type="text" class="form-control input-sm chat_input"
                                [(ngModel)]="messageInput" (keyup.enter)="sendMessage()"
                                placeholder="Write your message here..." />
                            <span class="input-group-btn send-btn">
                                <button class="btn btn-primary btn-sm" (click)="sendMessage()"
                                    id="btn-chat">Send</button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3 col-md-3 position-right">
            <div class="accordion" id="accordionExample">
                <div class="accordion-item" *ngFor="let reply of tobiReplies;let i = index;">
                    <h2 class="accordion-header" [id]="'heading'+i">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#collapse'+i" aria-expanded="true" [attr.aria-controls]="'collapse'+i">
                            TOBi Reply #{{i+1}}
                        </button>
                    </h2>
                    <div [id]="'collapse'+i" class="accordion-collapse collapse" [class]="{'show': i+1 === tobiReplies.length}"
                        [attr.aria-labelledby]="'heading'+i" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <code>{{reply.data | json}}</code>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <span *ngIf="!showChatWindow" class="float" (click)="showChatWindow = !showChatWindow; chatWindowOpen=true;">
        <i class="fa fa-weixin my-float"></i>
    </span>
</div>