import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { AppComponent } from './app.component';
import { CardComponent } from './components/card/card.component';
import { SuggestionComponent } from './components/suggestion/suggestion.component';
import { LinkoutComponent } from './components/linkout/linkout.component';

import { FormsModule } from '@angular/forms';
import { CarouselComponent } from './components/carousel/carousel.component';
import { AdaptiveCardComponent } from './components/adaptive-card/adaptive-card.component';
import { AttachmentComponent } from './components/attachment/attachment.component';
//https://e2481a4b.ngrok.io
const config: SocketIoConfig = { url: window.location.protocol + "//" + window.location.host, options: {} };

@NgModule({
  declarations: [
    AppComponent,
    CardComponent,
    SuggestionComponent,
    LinkoutComponent,
    CarouselComponent,
    AdaptiveCardComponent,
    AttachmentComponent
  ],
  imports: [
    BrowserModule,
    SocketIoModule.forRoot(config),
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
